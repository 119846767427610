import { TextPopup, TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Sleeping_suns_maison() {
    return {
        "date": <span>~5800 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><s><TextInlineLink name={"bs_state_the_great_monarchy"} /></s>, <TextPopup name={"bs_popups_state_shark_republic"} /></span>,
        "habitats": <span>a little</span>,
        "people": <span>Mihjelmians, <TextInlineLink name={"bs_people_earthlings"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>2</span>,
        "g": <span>1.01 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>terraformed to earthly</span>,
        "system": <span>Sleeping Suns</span>,
        "sector": <span><TextInlineLink name={"bs_space_shark_sector"} text={"Shark"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"agrarian world"} /></span>,
        "imgs": [{ "short": "bs_wiki_sleeping_suns_maison_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the world</h3>
                <p className="card-text text-justify">
                    <span className="strong">Sleeping Suns' Manor</span> is another <TextInlineLink name={"bs_planet_earth"} text={"Earth"} /> colony of <TextInlineLink name={"bs_timeline_underlight_age"} text={"pre-Light times"} />. For a long time it was inhabited by small communities, considered an ideal world for hermits. But eventually all the settlements died out with the opening of light flights, because young people flew off en masse to the metropolis. Since then, only Holdraig Castle remains, and its glory days are long gone.
                </p>
                <p className="card-text text-justify">
                    After <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} />:
                </p>
                <p className="card-text text-justify">
                    Few people here understand what happened to the Shark Sector and the Monarchy. For now, Rosalie's departure with the mysterious ragamuffins is the most urgent cause for gossip among the locals. The time to discuss the revolution is yet to come.
                </p>
            </>
    }

}

