import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from "../../../../../components/cms/wiki_functions";

export function Salibar_kardenos() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "name": "Salibar Cardenos",
        "fullname": <span>Salibar <small>«Armand»</small> Cardenos</span>,
        "born": <span>73 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>72</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_agathonians"} text={"Agathonian"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "relatives": <span><TextInlineLink name={"bs_char_xavier_kardenos"} /></span>,
        "work": <span><TextInlineLink name={"bs_comp_cms"} text={"TMS"} /> colonel</span>,
        "status": <span className={"text-danger"}>deceased</span>,
        "imgs": [
            { "short": "bs_char_salibar_kardenos_1_jpg_500", "big": "bs_char_salibar_kardenos_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Work in TMS and <TextInlineLink name={"book_cydonian_girl"}
                /></h3>
                <p className="card-text text-justify">
                    <span className="strong">Salibar</span> is the younger brother of Xavier Cardenos, who all his life dreamed of following in his footsteps. He followed him to the TMS and tried to become a field agent in the <TextInlineLink name={"bs_state_free_worlds"} text={"Free worlds"} />, but his skills and physical attributes were not conducive. So Salibar spent many years in the analytics department and was not able to pass the right exams the first time. When he finally managed to get a task «in the field» in Sambora, the Free Trade League people quickly discovered him and nearly killed the man. The TMS had to outfit an entire SWAT team to get him off New Havana, and then <TextInlineLink name={"bs_char_esora_eve"} text={"Eve Esora"} /> got the guy off the planet in a secret compartment of the hold.
                </p>
                <p className="card-text text-justify">
                    After such a fiasco, an agent's career usually ends and he moves permanently into the archives, but Xavier took a little patronage for his brother and allowed him to participate in several operations with extremely high odds of success. By giving himself more credit than he should have, Salibar was able to wash away the shame after his first assignment and even earned the nickname «Armand» which many interpreted as «Ahriman», one of the death gods of the ancient <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} />.
                </p>
                <p className="card-text text-justify">
                    Such exaggeration of his skills and merits played a cruel trick on the agent. With his older brother's support behind him, he was able to lead a detachment sent by the TMS to <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Monarchy"} /> during the <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Mihjelm Rebellion"} />. And it was even successful (largely thanks to <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ivar De Karma"} />).
                </p>
                <p className="card-text text-justify">
                    What Armand did not know, however, was that a position in the TMS and influential friends do not save you from a point-blank shot. When he blurted out to Ivar that he had found out something important about <TextInlineLink name={"bs_char_de_levine_rosalie"} text={"Rosalie's"} /> true nature, he was instantly shot several times in the torso. De Karma gunned him down on <TextInlineLink name={"bs_planet_earth"} text={"Earth"} />, on the last day of the Great Monarchy's existence. Poetic.
                </p>
            </>
    }
}