import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function EsoraEve() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "name": "Eve Esora",
        "fullname": <span>Eve Maria <small>«Lira»</small> Esora</span>,
        "born": <span>41 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>40</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_agathonians"} text={"Agathonian"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "parents": <span>Gregorios and Serena Esora</span>,
        "relatives": <span>Tamira and Alec Esora</span>,
        "work": <span><strike><TextInlineLink name={"bs_comp_cms"}
            text={"1st Diversionary Directorate"} /></strike></span>,
        "imgs": [
            { "short": "bs_char_esora_eve_1_jpg_500", "big": "bs_char_esora_eve_1_jpg_full" },
            { "short": "bs_char_esora_eve_2_jpg_200", "big": "bs_char_esora_eve_2_jpg_full" },
            { "short": "bs_char_esora_eve_3_jpg_200", "big": "bs_char_esora_eve_3_jpg_full" },
            { "short": "bs_char_esora_eve_4_jpg_200", "big": "bs_char_esora_eve_4_jpg_full" },
            { "short": "bs_char_esora_eve_5_jpg_200", "big": "bs_char_esora_eve_5_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_black_sun"} />
                </div>,
            ],
        "text":
            <>
                <h4 className="title-bg">Youth and career in the TMS</h4>
                <p className="card-text text-justify">
                    <span className="strong">Eve</span> is a protagonist in <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian Girl»"} />, <TextInlineLink name={"book_cydonian_autumn"} text={"«Cydonian Autumn»"} />, and several subsequent novels. An <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} /> commando who has seen all the hot spots of the <TextInlineLink name={"bs_state_free_worlds"} text={"Free worlds"} />. The daughter of a prominent Agathon politician, she severed all ties with her family to devote herself to her work. Eva was the first to notice a pattern in <TextInlineLink name={"bs_spec_vitruvianus"} text={"Vitruvian"} /> kidnappings and sounded the alarm, despite the taunts of her colleagues. Ironically, it was dedication that ruined the girl's career and forced her to go to conflict with her country to save all mankind.
                </p>
                <p className="card-text text-justify">
                    <TextInlineLink name={"bs_char_esora_eve"} text={"Esora's"} /> story is unlike that of most of her peers, especially those from wealthy and respectable families. By birth she was the heiress of a considerable fortune and, thanks to the authority of her family, she could become anything without the slightest effort: from the owner of a big business to an official of the highest rank. She spent her youth in greenhouse conditions, surrounded by influential people and their promiscuous children. Although to the surprise of the latter she did not choose to party with her classmates, but instead chose very active and in some places dangerous entertainment, which often brought her to the hospital.
                </p>
                <p className="card-text text-justify">
                    Eve graduated from a private school in Agathon's Hovering Quarter, a very expensive place even by the standards of the capital. She was not at all impressed by her time there, on the contrary: she could barely stand the snobbish, aristocratic attitude around her. Behavioral problems were commonplace for her.
                </p>
                <p className="card-text text-justify">
                    The bland life of the pampered aristocracy drove Eve into despondency. She quickly realized that she could not withstand even a year of desk work, so she chose to pursue a career as an officer of Agathon's security service. Her parents tried with threats and pleas to return the girl to the right path, but as soon as she finished school she immediately left home and stepped on the dangerous path of an officer of the 1st Diversionary Directorate.
                </p>
                <p className="card-text text-justify">
                    The defense doctrine of the <TextInlineLink name={"bs_state_republic_of_tiara_minor"} /> implies a joint army and intelligence services. Although Eve studied specifically as a future Agathon officer, many people from other states in the <TextInlineLink name={"bs_space_vikoveera"} text={"Vikoveera sector"} /> were in the same classroom with her. There she met many like-minded students, and for the first time in her life she felt that she was not a lone black sheep, but part of something larger and truly important.
                </p>
                <p className="card-text text-justify">
                    Interestingly, even before her first assignment, Eve managed to quarrel with the family for good: in response to insults from her younger sister, she could not keep her fists in her pockets and disfigured sister's face with several well-placed blows. From that moment on, the way to the paternal home was barred, ane Eve completely cut out of the Esora family's life.
                </p>
                <p className="card-text text-justify">
                    She didn't stay in debt and never even thought about her relatives, instead devoting all her time to fighting pirates on the fringes of civilization. In twelve years Eva had risen to captain of sabotage and earned the respect of her peers through her skills, not her last name.
                </p>
                <h4 className="title-bg">Revolution in <TextInlineLink name={"bs_state_the_great_monarchy"} /> (<TextInlineLink
                    name={"book_cydonian_girl"} />)</h4>
                <p className="card-text text-justify">
                    Shortly before the events of <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} />, she discovered a correlation between the mass disappearances of people in different regions of the galaxy and began to sound the alarm. Eve thought they were the work of non-humans, but no one predictably listened to her. She had to look for help outside the Security Service.
                </p>
                <p className="card-text text-justify">
                    At the same time she was sent on a business trip to <TextInlineLink name={"bs_planet_michelm"} text={"Mihjelm"} />, where Eve met <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ivar De Karma"} />. He impressed her with his tactical abilities and Esora even dared to have a little romance with the reckless Cydonian.
                </p>
                <p className="card-text text-justify">
                    There she also met <TextInlineLink name={"bs_char_de_levine_rosalie"} text={"Rosalie De Levine"} />, a young Cydonian girl who was only half human. Esora and De Karma decided that this girl was the key not only to finding the mysterious kidnappers, but also to the future of humanity. Rosalie's abilities could advance genetics and medicine for centuries to come. In addition, communication with de Levin shed light on some of the details of what is happening in the galaxy, and these details frightened both the brigade commando and the former admiral.
                </p>
                <p className="card-text text-justify">
                    Despite her remarkable abilities, Rosalie should have been protected from the predatory clutches of the Tiara Minor Security and other organizations that would have easily used the Cydonian girl to create weapons. So when Esora's colleagues learned of de Levin's oddities, Ivar did not hesitate to shoot them on sight.
                </p>
                <p className="card-text text-justify">
                    Following her oath, Eve should have taken revenge on Ivar, but she made a different choice. Despite her conflicting feelings, she accepted the idea that organizations like the TMS should not know about Rosalie. Therefore, Esora did not defend her colleagues and chose to return to Agathon to use the information gained during the uprising to warn the Agathonians of the impending alien invasion.
                </p>
                <p className="card-text text-justify">
                    Despite the successful outcome of the <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Mihjelm Rebellion"} />, things didn't end so rosy for Eve: her colleagues suspected Esora of involvement in her superior's death and sent the girl to court martial.
                </p>
                <p className="card-text text-justify">
                    Eve was sentenced to 40 years of hard labor and stripped of all ranks and honors. At the end of <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} /> Esora was sent to the Alcibiades penal colony on <TextInlineLink name={"bs_planet_alkiviad"} text={"Alcibiades-4"} />.
                </p>
                <h4 className="title-bg">Interesting facts</h4>
                <p className="card-text text-justify">
                    Before she met Rosalie, she'd never tasted green tea in her life.
                </p>
                <p className="card-text text-justify">
                    In the original version of the text, Eve had an entire squad of commandos under her command, called the Velimore Cats. But it was cut out for lack of use (as well as the planet Velimore), only a character named «Ap» was left - we will meet him in <TextInlineLink name={"book_cydonian_autumn"} text={"«Cydonian autumn»"} />.
                </p>
                <h4 className="title-bg">Evolution of the character</h4>
                <p className="card-text text-justify">
                    Esora was not supposed to appear in the story at all. It just became clear at some point that it was necessary to make the main character's company more colorful in order to diversify both herself and the reading experience. The Agathonians, who were just around the corner, entered the scene. Eve had to be introduced into the story first, and then an entire squad of commandos, nicknamed «Velimore Cats». In that version, saboteurs were being trained on Velimore to sabotage the country and Eve was supposed to be the main spearhead of the attack.
                </p>
                <p className="card-text text-justify">
                    Then it became clear that introducing a whole squad was too much, since everyone needed to be introduced to the reader. So only <TextInlineLink name={"bs_char_kaory_nazil"} text={"Nazil"} /> remained in <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} />, who stopped being her subordinate and became her partner.
                </p>
                <p className="card-text text-justify">
                    At one point, the backstory with Velimore was also cut, as the storyline was already threatening to turn into a classic, unsupportable three-volume book. At the same point, her mentor, Colonel Serge Barr, a hard-boiled warrior named after an old friend of mine, also disappeared from the narrative.
                </p>
                <p className="card-text text-justify">
                    Eve did not join De Karma's squad right away. At first she had her own storyline, and she crossed paths with the main characters at various intervals. She had to perform secret tasks for the Chancellor, who was looking for time capsules left by the <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} /> that held the blueprints for long-forgotten weapons. Then the storyline seemed too banal to me: a lost civilization and ancient artifacts in museums, capable of destroying the universe. Something smells familiar, doesn't it?
                </p>
                <p className="card-text text-justify">
                    In the same version of the story it was Eve who first met The Whip, not Rosalie: he caught up with her and took away her time capsule. And at the same time, he beat her up, leaving an unforgettable impression of the first encounter.
                </p>
                <p className="card-text text-justify">
                    Then it became clear that not only the time capsules look boring, but also the political intrigues of the Chancellor. The world turned out to be overloaded, the narration stomped on the place because of the necessity to serve all the lines, some of which did not give anything useful at all.
                </p>
                <p className="card-text text-justify">
                    So it was decided to transfer Eve to De Karma and deprive her of her own story. Thus the poor thing went from an indispensable character to just a grumpy Agathonian in a walkie-talkie. But that's okay, she'll fix it from here on out, I promise.
                </p>
            </>
    }
}
