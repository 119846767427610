import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Scellurae() {
    return {
        "date": <span>946 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextPopup name={"bs_popups_state_shark_republic"} /></span>,
        "habitats": <span>32 million</span>,
        "people": <span>Scellurians, <TextInlineLink name={"bs_people_earthlings"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>none</span>,
        "g": <span>0.99 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>terraformed to earthly, <span className='badge bg-danger'>extremely dangerous</span></span>,
        "system": <span>Alevtina</span>,
        "sector": <span><TextInlineLink name={"bs_space_shark_sector"} text={"Shark"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"agrarian world"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "imgs": [{ "short": "bs_wiki_scellurae_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Scellurae</span> is an Earth-type garden planet with an acidic biosphere, colonized over a thousand years ago. In typical human fashion, they’ve managed to wipe out all native fauna and tinker with the flora so much at the genetic level that it’s now hard to tell it apart from Earth’s -just with a bit more bite to it.
                </p>
                <p className="card-text text-justify">
                    Scellurae suffered great financial losses due to the orbital battle between the Spiders and <TextInlineLink name={"bs_popups_army_echelon_of_the_fives"} text={"The Fives"} />. The locals have had to invest heavily in cleaning up the biosphere of traces of ionic fuel and radiation. Nevertheless, the fact that it was here that the legendary break-in of <TextInlineLink name={"bs_planet_earth"} text={"Earth"} /> communications took place has brought the planet fame throughout the galaxy. Demand for its unique farm produce increased tenfold, and tax breaks from the Mihjelmians more than offset the costs.
                </p>
                <p className="card-text text-justify">
                    The Scellurians even considered full independence, but <TextInlineLink name={"bs_planet_michelm"} text={"Mihjelm"} /> did not support the idea. Ironic.
                </p>
                <p
                    className="card-text text-justify"><span
                        className="strong">Ivar's notes about Scellurae from <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} />:</span>
                </p>
                <blockquote>
                    <p className="card-text text-justify">
                        <span className="blockquote-footer">
                            The days are almost terrestrial.
                        </span>
                    </p>
                    <p className="card-text text-justify">
                        <span className="blockquote-footer">
                            Nature is unfriendly: water and soil are polluted with sulfur compounds. Biosphere is saturated with acid.
                        </span>
                    </p>
                    <p className="card-text text-justify">
                        <span className="blockquote-footer">
                            Your helmet must not be taken off in any case - plant juice, morning dew, and even the fog will eat your face with gusto.
                        </span>
                    </p>
                    <p className="card-text text-justify">
                        <span className="blockquote-footer">
                            Local flora feels great in such conditions, so you should not touch wild plants. Only man-made ones, and there are plenty of them here.
                        </span>
                    </p>
                    <p className="card-text text-justify">
                        <span className="blockquote-footer">
                            The world is mostly agrarian, growing delicacies in the open air. On other planets, local fruits would require special care. Therefore, many local species have not even tried to graft on other worlds, which makes their fruit quite expensive. So we try not to burn anything unnecessary.
                        </span>
                    </p>
                    <p className="card-text text-justify">
                        <span className="blockquote-footer">
                            There is almost no fauna, except for rare insects.
                        </span> <span className="code">
                            DO NOT TOUCH THEM WITH YOUR HANDS!
                        </span>

                    </p>
                    <p className="card-text text-justify">
                        <span className="blockquote-footer">
                            Almost the entire surface is farmland, no forests. We will mostly wander through other people's gardens.
                        </span>
                    </p>
                    <p className="card-text text-justify">
                        <span className="blockquote-footer">
                            The population is a few million, there are no cities, only small communes scattered across the steppes.
                        </span>
                    </p>
                    <p className="card-text text-justify">
                        <span className="blockquote-footer">
                            The locals strongly supported Mihjelm's rebellion because of the exorbitant taxes, imposed by Earthlings during the War, which have never been lowered since. But the arrival of the Fifth Echelon seems to have tempered the fervor.
                        </span>
                    </p>
                </blockquote>
            </>
    }
}

