import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function The_free_worlds() {
    return {
        "capital": <span><TextInlineLink name={"bs_planet_samborae"} /> (unofficially), <TextInlineLink name={"bs_planet_new_havana"} /> (unofficially), <TextInlineLink name={"bs_planet_khal_vernon"} /> (unofficially)</span>,
        "form": "varies from planet to planet",
        "currency": <span><TextInlineLink name={"bs_currency_charmo"} text={"Cydonian charm"} />, <TextInlineLink
            name={"bs_currency_teros"} text={"Agathonian teros"} /></span>,
        "langs": <span><TextInlineLink name={"bs_lang_samborian"} text={"Samborian"} />, <TextInlineLink
            name={"bs_lang_cydonian"} text={"Cydonian"} />, <TextInlineLink name={"bs_lang_earth"} text={"Earthlin"} /></span>,
        "leader": <span>none</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        // "sectors": <span>1 (but very big)</span>,
        // "worlds": <span>unknown</span>,
        "people": <span><TextInlineLink name={"bs_people_samborians"} />, <TextInlineLink
            name={"bs_people_ajliree"} />, <TextInlineLink name={"bs_people_cydonians"} />, <TextInlineLink
                name={"bs_people_earthlings"} />, <TextInlineLink name={"bs_people_agathonians"} /> et al.</span>,
        "imgs": [{ "short": "bs_wiki_the_free_worlds_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">The Free worlds</span> – a huge area in Brianne Cluster, which, in essence, IS the Brianne Cluster. The Free worlds occupy exactly one sector, Sambora. A sector the size of <TextInlineLink name={"bs_state_cydonian_priority"} text={"Priority"} /> and <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Domination"} /> together combined. Many times the galactic bosses wanted to divide Sambora into smaller territories, but met invariably with resistance, or worse, complete indifference from the locals.
                </p>
                <p className="card-text text-justify">
                    The Free Worlds have no unified authority, are not embedded in galactic politics, and are not represented in any major international organizations. The exact population and even just the number of inhabited worlds in this sector is unknown. One thing is certain: there is no point in seeking contact with Samoborians, to make them full members of the galaxy. They simply do not want it.
                </p>
                <p className="card-text text-justify">
                    Local governments, which vary greatly from planet to planet (and sometimes from continent to continent - crazy!), are rarely recognized by anyone but their neighbors, and are often semi-legal formations, operating on the basis of social contract and tradition more than on the basis of legal procedures. Some worlds are even controlled by <TextInlineLink name={"bs_comp_free_trade_league"} text={"Free Trade League"} /> - the local equivalent of the Yakuza, which has millions of pirates and untold numbers of smugglers throughout the galaxy. This organization and a number of others like it are involved in everything that is forbidden in civilized galaxy: arms trade, stolen relics, forbidden substances, sometimes even slavery. In the League's worlds dubious medical experiments, development of biological weapons and other vile things flourish.
                </p>
                <p className="card-text text-justify">
                    The form of government also varies greatly from state to state, from which in one system there can be president, king, and CEO at the same time.
                </p>
                <p className="card-text text-justify">
                    The outer chaos turns out to be quite controllable and in some places even more orderly than the rest of the galaxy. Most worlds are as well-fed and warm as <TextInlineLink name={"bs_planet_cydonia"} text={"Cydonia"} /> or <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} />, and their populations are often employed in perfectly legal industries. The only problem is that there's almost nowhere to market their products due to the nearly universal embargo on trade with the Samborians. Therefore, most worlds provide food and clothing to organizations similar to the League and often have army, hardly distinguishable in appearance and activities from a pirate gang.
                </p>
                <blockquote>
                    <p className="card-text text-justify text-italic">
                        «When it comes to laws and the rights of citizens, of course, their number and even their existence vary. It is difficult to identify a clear pattern, but it is safe to say that even in the most wild Samborian corners reigns at least one rule: don't touch others if you don't want them to touch you. It works well outside metropolitan areas like <TextInlineLink name={"bs_planet_new_havana"} text={"New Havana"} /> and <TextInlineLink name={"bs_planet_khal_vernon"} text={"Khal-Vernon"} />, which have all the trappings of statehood. So even if you don't know what restrictions apply in one world or another, just stay out of the way. You'll be fine... Oh, and don't expose your gun - someone might get nervous and shoot first»
                    </p>

                    <small>
                        <TextInlineLink name={"bs_char_ursula_adams"} />,<TextInlineLink
                            name={"bs_universe_bird_of_passage_guide_to_milky_way"} />
                    </small>
                </blockquote>

                <h5>Julian Harari, «Homo sapiens: the true story of people from The Old Earth», year 265 AT</h5>
                <p className="card-text text-justify">
                    «Speaking of Free Worlds, it's worth starting with the story of how they actually came into being. This is not story of pirates who defied the established order, nor of the brave Alamarsie daredevils who escaped from the gaze of <TextInlineLink name={"bs_popups_plantewalkers"} text={"Planetwalkers"} /> on the other side of the galaxy. It's about cooperation. And the desire for freedom. Although it did start with the Alamarsie.
                </p>
                <p className="card-text text-justify">
                    About 5,000 years ago (5196 AT. - editor's note), nomadic people discovered the uncharted expanses around the planet Sambora. It was the farthest part of the galaxy that humans at the time were venturing into. There <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} />, using first primitive light machines, explored the surroundings by many parsecs around. They built a network of navigational beacons, made many robotic emergency stations, and populated orbits of several particularly remarkable worlds. None of them at the time supported life.
                </p>
                <p className="card-text text-justify">
                    A little less than a century later, <TextInlineLink name={"bs_planet_earth"} text={"Earth's"} /> terraforming automatons got there and started turning Sambora and neighboring worlds into the likeness of Mother Earth. As the centuries passed, the Alamarsie actively took advantage of the benefits of terraforming and were certain that planetwalkers would never get there in the flesh.
                </p>
                <p className="card-text text-justify">
                    This was a mistake. About 4,100 years ago (4097 AT. - editor's note) a huge colonization fleet approached Sambora. The inhabitants of Old Space, weary of Tristan's wars and endless fighting amongst Earth rulers, found records of terraformers being sent to this part of space and decided to see what came of it.
                </p>
                <p className="card-text text-justify">
                    They claimed these worlds and the Alamarsie, realizing that the more advanced and heavily armed aliens posed a grave danger, agreed to share the planet with them. After all the nomads live in space - they need the surface only as a resource provider.
                </p>
                <p className="card-text text-justify">
                    The newcomers shared modern technology with Alamarsie and in return received maps of the surrounding space. From that moment the Free Worlds of Sambora began, a confederation of independent planets that only wanted one thing: to be left alone.
                </p>
                <p className="card-text text-justify">
                    There was no <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} /> yet, and <TextInlineLink name={"bs_planet_cydonia"} /> & <TextInlineLink name={"bs_planet_ajliraen"} /> were nothing more than small, little-known planets somewhere on the periphery of the known universe. But the freedom-loving Samborians were already raising and conquering the galaxy, forming a bizarre alliance of planetwalkers and nomads…»
                </p>
            </>
    }
}

