import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Tajbennae() {
    return {
        "date": <span>-</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "moons": <span>none</span>,
        "habitats": <span><s>1</s>0</span>,
        "g": <span>1.07 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>terraformed to earthly</span>,
        "system": <span>Cortázar</span>,
        "sector": <span><TextInlineLink name={"bs_space_ardimare_sector"} text={"Ardimare"} /></span>,
        "type": <span>earth-like uninhabited</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "imgs": [{ "short": "bs_wiki_tajbennae_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Taibenna</span> – an officially uninhabited planet from the Priority Terraformed Worlds Reserve. Mass colonization never took place, Taibennae was kept away from industrialists and urbanists, in case, for some reason, the inhabitants of other worlds needed to be resettled.
                </p>
                <p className='card-text text-justify'>
                    <span className='strong'>After <TextInlineLink name={"book_hyperbeacons"} text={"«Beacon thieves»"} />:</span>
                </p>
                <p className='card-text text-justify'>
                    A base of unknown intelligent beings, temporarily called «carrot humanoids», was discovered on the planet. The creatures behaved aggressively: they abducted people from other worlds and conducted mutilation experiments on them. <TextInlineLink name={"bs_char_skarsgard_oliver"} text={"Oliver Skarsgård"} /> put an end to this by aiming the guns of the 77th Priority Borderfleet directly at the creatures' base - not only destroying the alien outpost, but also causing enormous damage to the Taibennae environment.
                </p>
            </>
    }
}

