import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Telenmark() {
    return {
        "date": <span>2122 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_aethurian_republic"} /></span>,
        "habitats": <span>53 billion (37 on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_aethurians"} />, <TextInlineLink name={"bs_people_agathonians"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>2</span>,
        "g": <span>1.0 terrestrial</span>,
        "atmosphere": <span><span className='badge bg-success'>suitable for humans</span></span>,
        "biosphere": <span>dead</span>,
        "system": <span>Telenmark-Gemini</span>,
        "sector": <span>-</span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"metropolis"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_calliara_space"} /></span>,
        "area": <span></span>,
        "imgs": [{ "short": "bs_wiki_telenmark_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the world</h3>
                <p className="card-text text-justify">
                    <span className="strong">Telenmark</span> is a metropolis in the center of the Aethurian Republic. It serves as a transshipment base for many trade hyperruns between Old Space and Calliara Space, as it is located near the «<TextInlineLink name={"bs_planet_earth"} text={"Earth"} />-<TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} />-<TextInlineLink name={"bs_space_ternerian_end"} text={"Ternerian End"} />» <TextInlineLink name={"bs_tech_hyperrun"} text={"route"} />.
                </p>
            </>
    }
}

