import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function The_great_monarchy() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "capital": <span><TextInlineLink name={"bs_planet_earth"} /></span>,
        "form": <span>constitutional monarchy</span>,
        "currency": <span><TextInlineLink name={"bs_currency_imperium"} text={"Terrestrial Imperium"} /></span>,
        "langs": <span><TextInlineLink name={"bs_lang_earth"} text={"Earthlin"} /></span>,
        "leader": <span>Monarch <TextInlineLink name={"bs_char_alkanarra_siel"} /></span>,
        "region": <TextInlineLink name={"bs_space_the_old_space"} />,
        // "sectors": <span>6</span>,
        "worlds": <span>976 (32 <TextInlineLink name={"bs_universe_planet_types"} text={"metropolises"} />)</span>,
        "people": <span><TextInlineLink name={"bs_people_earthlings"} text={"Earthlings"} />, Mihjelmians et al.</span>,
        "imgs": [{ "short": "bs_wiki_the_great_monarchy_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>

                <p className='card-text text-justify'>
                    A word of caution: the data above is at the time of <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Mihjelm Rebellion"} />, since at the time of its end, no exact figures about the Monarchy could be said any more.
                </p>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    The state, named in the future the Great Monarchy, began its history even before the light flights. At that time it was a federation of semi-independent planets scattered throughout the Old space within <TextInlineLink name={"bs_space_sector_zero"} text={"Zero"} /> and neighboring sectors. Earth did not have complete control over them because there was neither fast enough transportation nor communication. News came with a delay of decades, and in some places even centuries late.
                </p>
                <p className='card-text text-justify'>
                    Under such conditions, many worlds lost contact with the ancestral home of mankind forever, but some still created trade routes to exchange valuable findings and technology. This is how the human <TextInlineLink name={"bs_maps_oecumene"} /> came into being under the aegis of still-existing, though heavily reformed United Nations.
                </p>
                <p className='card-text text-justify'>
                    Over time, ties strengthened and Earthlings began to demand more control and, most importantly, resources. Humanity's homeland was gradually sinking into garbage, concrete, and slums. The economy was declining and many saw the solution to all problems in other worlds. But not by relocating there (which was impossible for tens of billions of people), but in the flow of materials and freeze-dried food. Simple logic suggested, that if all the colonies sent a resource ship to Earth every year, in a few decades the flow would become steady and only increase. In this way, Earthlings would be able to feed at a higher quality and devote resources to clearing the planet of dumps, creating new colonies, and other useful things.
                </p>
                <p className='card-text text-justify'>
                    It is not hard to guess that the colonies themselves did not want to become resource appendages to a distant world, which new generations of colonists saw only in pictures. Especially since the colonies had enough problems of their own, unique to each planet. One by one, they cut the thin strings of interplanetary communication and the cosmos became deaf to the demands of Earth's rulers.
                </p>
                <p className='card-text text-justify'>
                    This provoked a series of civil wars between rudimentary earthly states and led to to the power of of militaristic dictators who took over much of the planet. They declared the colonists traitors and were going to take back «theirs» by force. It took a long time, but the consolidated around the idea of revanchism, the Earthlings managed to create a fairly efficient light engine <TextInlineLink name={"bs_tech_engine_overlight"} text={"Einstein's"} /> type (good old «Alcubierre»). From this moment (ca. 5500 <TextInlineLink name={"bs_timeline"} text={"BT"} />) <TextInlineLink name={"bs_timeline_light_travel_times"} text={"The Light Age"} /> is counted down. Since previous prototype engines for centuries only killed their creators or were simply ineffective.
                </p>
                <p className='card-text text-justify'>
                    So aggression and militarism suddenly gave a boost to humanity. And started a series of wars in the Old space, which not only brought much grief to the galaxy, but also spread light engines throughout the human colonies.
                </p>
                <p className='card-text text-justify'>
                    The next centuries were marked by sluggish conflicts, which periodically turned into an extreme phase. Only <TextInlineLink name={"bs_char_alkanarra_tristan"} />, nicknamed the Conqueror, was able to elevate interstellar violence to an entirely new level. He came to power around the year 4200 BT against the backdrop of new earthly strife. He didn't come for nothing, but with a plan, as reliable as an Aethurian clock. Earth was in urgent need of rescue from overpopulation and ruined ecology, that even the most advanced technology (and by the standards of the 21st century, divine technology) could not restore.
                </p>
                <p className='card-text text-justify'>
                    Tristan's plan was to create a large, fast fleet, equipped with the latest technology and a new kind of force: the <TextInlineLink name={"bs_weaponry_space_force"} text={"Space marines"} />. The best Earth fighters were trained to land directly from orbit and armed with handheld railguns, weapons never before seen in the colonies.
                </p>
                <p className='card-text text-justify'>
                    Taking advantage of the faster light engines recently invented on Earth, Tristan
                    began to conduct lightning-fast daring operations and take control of entire planets in a matter of
                    years.
                    The nearest colonies were incapable of resisting an enemy that managed to start and finish
                    the invasion faster than word of it reached other worlds.
                </p>
                <p className='card-text text-justify'>
                    So Tristan was able to plant the Earth flag on dozens of worlds and hundreds of smaller planetoids,
                    but
                    strength to get beyond Old Space he lacked: worlds such as Cydonia and Eilirén were
                    perfectly safe.
                </p>
                <p className='card-text text-justify'>
                    Despite the success of the new tactics and advanced weapons, capturing the planet was easier than
                    to hold. In those days, total submission of a world was long, expensive,
                    and rather boring - only true fanatics of their cause could <span
                        className='strike'>live to</span> wait for the process to be completed.
                    Therefore, Tristan did not engage in complete occupation and assimilation, but only showed what
                    would happen if
                    to resist the earthlings. His demand was a regular supply of resources and food to Earth. In return
                    the inhabitants of the ancestral homeland promised not to make new raids. In fact, Tristan was
                    engaging in banal racketeering.
                    He did so knowing that there is no power in the galaxy comparable to Earth: the poor inhabitants of
                    overpopulated
                    slums were happy to go to war just for a soldier's ration. They didn't care where
                    and who they shot. They were opposed by the sparsely populated colonies, where people aspired to
                    education,
                    self-development and maximum comfort - few were prepared to defend their world against the onslaught
                    of a superior
                    enemy and even fewer were willing to do so for years.
                </p>
                <p className='card-text text-justify'>
                    Over time, Tristan succeeded in creating a unified, albeit very fuzzy, state entity. He
                    died at the age of
                    almost 200 years old and did not have time to see the true flowering of his empire. At that time it
                    was
                    only a collection of autonomies, which were sluggishly subordinate to the center and sent there a
                    certain amount of
                    resources. The actual forms of government and titles of rulers varied from planet to
                    planet, and some
                    local leaders didn't even always understand exactly who they were subordinating to and what was
                    going on there in general on
                    this Earth of yours.
                </p>
                <p className='card-text text-justify'>
                    The order was maintained for a very long time because of imperfect movement technology and great
                    acclimatization problems in new worlds,
                    which ruined soldiers' health and morale.
                </p>
                <p className='card-text text-justify'>
                    Nevertheless, the Earthlings liked to feel like residents of the capital of an entire interstellar
                    empire. They
                    supported Tristan's descendants and allowed them to take almost unlimited power. And
                    it only grew and grew stronger over the centuries that followed. Along with it, interplanetary ties
                    were strengthened:
                    Earth spent colossal resources in perfecting light engines and weapons, becoming
                    increasingly oppressive dictatorship. At one point, its power and authority became so high that
                    Tristan's descendants, the Alcanarra family, declared themselves to be the very real Monarchs, and
                    Earth to be the capital of the Monarchy.
                    Not an ordinary one, but the Great one, as no one had ever created a full-fledged interstellar
                    empire before.
                </p>
                <p className='card-text text-justify'>
                    It's not hard to guess that after that, the wars only flared up with renewed vigor: Earth
                    fought <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} />, <TextInlineLink
                        name={"bs_planet_cydonia"} text={"Cydonia"} />, <TextInlineLink
                        name={"bs_planet_ajliraen"} text={"Eilirén"} /> and many other worlds that stubbornly refused
                    to recognize power of human ancestral homeworld.
                    These battles were not as successful as the conquest of Old Space: the distance was too
                    too far,
                    and the number of rebellious colonies increased year by year. At some point, the resources of even
                    such a gigantic empire
                    began to run out, and the smaller they were, the more nearby planets rebelled.
                </p>
                <p className='card-text text-justify'>
                    The instability led to around 1700 BT Earthlings losing the <TextInlineLink name={"bs_timeline_war_300"} text={"Three Hundred Years War"} /> to <TextInlineLink name={"bs_people_cydonians"} text={"Cydonians"} /> and thereafter finally abandoned any attempt to go beyond Old Space.
                </p>
                <p className='card-text text-justify'>
                    The first major conflict Earth has been involved in since then was <TextInlineLink name={"bs_timeline_galactic_war"} text={"First Galactic war"} />. This war launched process of disintegrating the society built by the Alcanarra dynasty and led first to colossal territorial losses and then to <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Second Mihjelm Rebellion"} />. It was the one that that finished off the shriveled empire.
                </p>
                <p className='card-text text-justify'>
                    The rebellion erupted in the Shark Sector some 14 months BT (during <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} />) and in a matter of days it turned into a an all-out nationwide demarche. The insanely brutal repression of the Earthlings did not help and in just a few weeks the Great Monarchy collapsed.
                </p>
                <p className='card-text text-justify'>
                    <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} /> ended with <TextInlineLink name={"bs_char_alkanarra_siel"} text={"Ciel"} />, the last of the Alcanarra, abdicated the throne and allowed all worlds to decide their own fate. Since then (12 months of BT) the Great Monarchy has ceased to exist.
                </p>
                <h3 className="title-bg">Interesting Facts</h3>
                <p className='card-text text-justify'>
                    The Army of the Monarchy was divided into several independent units called Echelons (with a capital letter). In the latter days of the country there was the Spider Echelon of the <TextInlineLink name={"bs_space_shark_sector"} text={"Shark sector"} />, <TextPopup name={"bs_popups_army_echelon_of_the_fives"} /> scattered around the country as military police, Night and Dawn Echelons, Zero Echelon for Earth defense, the Whale Echelon at the Calliara Space border, and the Cepheus Echelon at the <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Eilirén"} /> border.
                </p>
                <p className="card-text text-justify">
                    Where this name came from is not known for certain. The most plausible theory is that due to navigational problems at the dawn of light time, armies were sent to the theater of war not as an armada, but as a string of ships. They formed a kind of train, following the tracks of the vehicles ahead so as not to go off course. Otherwise, there was a great chance of losing direction and and be weeks, even months, late to the battlefield.
                </p>
                <p className="card-text text-justify">
                    Back then, warships were prepared for just one or a few battles, because the flight was long, and a war for a planet could go on for years. So they were given names according to where Echelon was going. Thus only a few survived to see the end of the empire they built.
                </p>
                <h3 id={"currency"} className="title-bg">Currency</h3>
                <p className="card-text text-justify">
                    <span className="strong">Terrestrial Imperium</span> or <span className="strong">zero</span> (In honor of the Zero Sun) – the currency of the Great Monarchy, and later the new earthly state that replaced it. Very volatile and dangerous asset. After the Shark Rising, it collapsed by more than four times.
                </p>
                <p className='card-text text-justify'>
                    Zero began to exist back in <TextInlineLink name={"bs_timeline_underlight_age"} text={"pre-Light times"} />, as a single means of exchange between populated star systems. Of course, it had no connection to gold or other things, because the new worlds contained all sorts of shiny junk. So for centuries the interstellar currency did not fluctuated, remaining a stable unit, and only the local currencies of the various planets changed their local exchange rate to to zero.
                </p>
            </>
    }
}

