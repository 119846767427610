import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function Tiara_minor() {
    return {
        "date": <span>~3800 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "type": <span>gas-dust nebula</span>,
        "owned": <span><TextInlineLink name={"bs_state_republic_of_tiara_minor"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_calliara_space"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "imgs": [{ "short": "tiara_minor_500", "big": "tiara_minor" }, {
            "short": "bs_wiki_republic_of_tiara_minor_map_en",
            "big": "bs_wiki_oecumene_map_en"
        }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <a data-fancybox="true" data-caption="" href={imgs["tiara_minor_2"].link}>
                    <img className="img-thumbnail-v2" style={{ "maxWidth": "300px", "float": "left", "marginRight": "1em" }} alt="Tiara Minor Nebula" src={imgs["tiara_minor_2_500"].link} fetchpriority="low" />
                </a>

                <p className="card-text text-justify">
                    <span className="strong">Tiara Minor Nebula</span>, aka Aktaf Kubarra in <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} /> (literally, «Shoulders of a Giant» or «Crown of a Giant», depending on the context). Settled relatively recently, after the opening of the light flights. The Agathon model of democracy rules here (yes, there's more than one) and almost the entire nebula is part of the <TextInlineLink name={"bs_space_vikoveera"} text={"Vikoveera sector"} />, with Agathon as its capital. Most worlds here are semi-independent satellite planets of Agathon.
                </p>

                <blockquote>
                    <p className="card-text text-justify">
                        «And where's the Tiara <span className="strong">Major</span> Nebula?», - you ask. «No idea», - I'll answer you. Ask the Alamarsie, because that's what they called it.
                    </p>
                    <small>
                        <TextInlineLink name={"bs_char_ursula_adams"} />, <TextInlineLink name={"bs_universe_bird_of_passage_guide_to_milky_way"} />
                    </small>
                </blockquote>
            </>
    }
}
