import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Terminology() {
    return {
        "text":
            <>
                <h4 className='text-center'>Terminology</h4>
                <p className="card-text text-justify">
                    <span className="strong">Backlash</span> is a situation where a vehicle wobbles back and forth during flight, making straight-line motion a bad cardiogram. The most frightening effect of backlash is at light speeds: you can easily miss a star system or even a country by distorting the flight vector.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">«Crazy Ivan»</span> – a maneuver in which the ship makes a sharp turn so that objects outside the range of guns hit it.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Cruising speed</span> – maximum effective speed of a ship to travel long distances, at which it consumes minimum of energy for each kilometer. Exceeding this speed is possible, but unprofitable.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Faraday cage (a farad)</span> – is a protective device reflecting external EM-impacts. Literally, it is a conductive cage, reminiscent of the first similar crafts of ancient earthlings. The modern one is different in that it is a bit more cunning and can adapt to almost any existing EM weapon: some ships even have a mechanism for absorbing and converting energy into useful work (boil a kettle for example).
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Fire contact</span> – the beginning of an engagement with the enemy.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Parallax, Ῥ</span> – in the context of naval warfare (not astronomy) means the amount of displacement of an object or ship relative to the plane in which the observer is located. In other words, what is the angle of fire contact if your ship stays in its current position. If Ῥ = 0.0 - the observed object is exactly in the same plane as the observer. Further, if the object moves vertically or horizontally on the sphere, the first or second digit grows (max value = 1.1 and this is equivalent to 0.0). Parallax is required by the captain of a warship together with the enemy coordinates in the three-dimensional sphere in order to understand how to change the machine position in the upcoming battle.
                </p>
                <p className="card-text text-justify">
                    A <span className="strong">phalanx</span> – is a closed formation of the fleet in which ships are lined up in several lines at a certain distance from each other and march in single front against the enemy. The simplest type of formation which does not take into account many factors that arise during battle. The phalanx is used mainly to suppress enemy fleets with fire and squeeze them out of their positions. In all other cases this formation may reduce maneuverability.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">«Rail»</span> – see the article <TextInlineLink name={"bs_weaponry_railgun"} text={"Railgun"} />.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">«Reds»</span> in the military term refers to targets marked as hostile (or potentially hostile, depending on how paranoid you are) by the detection systems. The actual color of the markings is irrelevant.
                </p>
                <p className="card-text text-justify">
                    In civilian navy this value is used in traditional astronomical sense.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">«Regressor»</span> – see the article <TextInlineLink name={"bs_tech_shield_kinetic"} text={"Kinetic Regression Field"} />.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Speed</span> – is measured in different units, depending on the context. At pre-light, it makes sense to count everything in kilometers per second, at near- and super-light - directly in photon speeds (1 light, 20 light, 100 light, etc.).
                </p>
                <h4 className='text-center'>Abbreviations</h4>
                <p className="card-text text-justify">
                    <span className="strong">ASOC</span> – see <TextInlineLink name={"bs_weaponry_oaac"}
                        text={"Anti-ship orbital complex"} />.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">BR/HBR</span> – Heavy Bomber. Contrary to its name, armed not only with
                    bombs
                    (which are exclusively suitable for planetary combat), but also with all sorts of missile and
                    torpedo weapons. «Heavy» in most cases means increased striking power compared to vehicles of its
                    class.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">CBN</span> – Cydonian battlenavy. Common designation for all <TextInlineLink
                        name={"bs_state_cydonian_priority"} text={"Priority"} /> warships.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">CCN</span> – Cydonian civilian navy. Denotes ships owned by citizens,
                    subjects, and companies of <TextInlineLink name={"bs_state_cydonian_priority"}
                        text={"Priority"} />.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">ERF</span> – Engineering Republican Fleet. A designation adopted by the <TextInlineLink name={"bs_state_republic_of_tiara_minor"} /> for dual-purpose ships carrying equipment that can be used for both exploration/mining and military reconnaissance.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">ETA</span> – expected time of arrival.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">GAM</span> – see <TextInlineLink name={"bs_weaponry_casm"}
                        text={"Guided anti-ship missile"} />.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">HC/HS</span> – Heavy Cruiser / Heavy Ship. A powerful fighting machine capable of performing missions both as part of a fleet and separately from it. Usually armed with all existing weapons and capable of crushing entire enemy fleets on its own. The prefix «heavy» means that the ship is armed with additional guns or has a reinforced armor compared to other machines of its design.
                </p>
                <p className="card-text text-justify">
                    The abbreviation HS stand for «heavy ship» and refers to a machine designed primarily to work with a squadron. They differ from cruisers primarily in a smaller number of doable tasks and reduced speed.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">HSS</span> – Heavy Support Ship. A combat vehicle that supports the squadron's main attack ships with fire. Armament, speed and armor varies from type to type. The prefix «heavy» indicates enhanced armament or armor compared to other vehicles of its class.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">IGM</span> – interplanetary guided missile. A dangerous weapon carrying a nuclear or anti-matter charge and capable of bypassing most defenses: the missile approaches its target at light speeds and can be intercepted either on launch or at the surface itself. In both cases the chances are very slim.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">KRF</span> – see the article <TextInlineLink name={"bs_tech_shield_kinetic"} text={"Kinetic Regression Field"} />.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">MS</span> – missile ship. A vehicle that uses missiles as its main offensive weapon.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">RFC</span> – The Royal Fleet of <TextInlineLink name={"bs_state_cydonian_priority"} text={"Cydonia"} />. Machines of the Priority diplomatic and government fleet.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">SGD</span> – a ship of the <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Great Domination"} />. The general designation for <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Eilirén"} /> War Fleet.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">SRF</span> – Special Republic Fleet. Abbreviation for <TextInlineLink name={"bs_comp_cms"} text={"Tiara Minor security"} /> ships.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">SSM</span> – Squadron Ship of the Monarchy. The designation for most warships of the <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Great Monarchy"} /> before its collapse during the <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Shark Rebellion"} />
                </p>
                <p className="card-text text-justify">
                    <span className="strong">SSM</span> – Surface-to-Space Missile. A rare type of weapon designed primarily to protect planets where presence must be concealed from scanners. Placed in camouflaged launch pits on the surface, they perform the same role as <TextInlineLink name={"bs_weaponry_oaac"} text={"ASOC"} />. SSMs are not very effective against large ships: those usually carry powerful scanners and laser anti-missile system which detects and destroys SSMs in advance.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">TFZ</span> – Targeted Fire Zone. A notional boundary between two ships or fleets, after which guidance systems are capable of correcting fire with acceptable accuracy. Each warship has its own TFZ, but the calculation is usually made from main calibers of main battle unit: other warships either open fire later, or fire less accurately - depends on current tasks of the fleet.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">ToW</span> – Theater of War. An area in which military action is already taking place, may take place, or will take place.
                </p>

            </>
    }
}
