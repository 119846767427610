import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Javet_pauline() {
    return {
        "name": "Paulinaer Yaveth",
        "fullname": <span>Paulinaer Yaveth</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_ajliraen"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_ajliree"} /></span>,
        "uncle": <span><TextInlineLink name={"bs_char_javet_bejsenaer"} /></span>,
        "born": <span>57 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>56</span>,
        "cousins": <span><TextInlineLink name={"bs_char_javet_eijenora"} />, <TextInlineLink
            name={"bs_char_javet_erbees"} /></span>,
        "work": <span>Admiral of the 1st Strike Fleet of <
            TextInlineLink name={"bs_state_ajliraenian_domination"} /> («Abyssinian Fist»)</span>,
        "imgs": [
            { "short": "bs_char_javet_pauline_1_jpg_500", "big": "bs_char_javet_pauline_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Youth</h3>
                <p className="card-text text-justify">
                    <span className="strong">Paulinaer</span> is a member of one of the lateral branches of the <span className="strong">Yaveth dynasty</span>. Her parents are far off the list of heirs, but that did not prevent them from gaining title and status in the court of Emperor Beisarion. For their loyal service and professionalism, he elevated them to the rank of hereditary aristocracy and added very generous financial bonuses.
                </p>
                <p className="card-text text-justify">
                    The reason was the success of the mining industry: Paulinaer's parents created a company that was able to bring the production of space steel in the Eiliren provinces to cyclopean levels. This saved the Domination's fleets from total defeat in the fields of the Galactic war and allowed them to make up for a significant portion of the losses in machinery from the war in just a few years. The Emperor later admitted that with lower production rates, the Eilireé would not have been able to withstand the onslaught of the <TextInlineLink name={"bs_people_agathonians"} text={"Agathonians and their allies"} />, and the Agathon fleet would have eventually defeated the Domination's forces. The country was on the brink at the end of the war: there were fewer ships than there were sailors willing to serve on them.
                </p>
                <p className="card-text text-justify">
                    Paulinaer's family was not alone in receiving the emperor's gratitude, but they were the only ones to gain status at court, as the family name was decisive in Beisarion's eyes.
                </p>
                <p className="card-text text-justify">
                    The girl herself was born about twenty years before this war. She was still in school when the conflict broke out and listened with rapt attention to her father's stories about how they were building more and more warships. His stories and successes paradoxically sparked in her a love of the navy rather than a desire to become an industrialist. So after high school she went to the military academy, but she graduated after Galactic war.
                </p>
                <p className="card-text text-justify">
                    Since then, Paulinaer has served in multiple fleets across the Domination and has commanded combat formations more than once in conflicts with the <TextInlineLink name={"bs_people_samborians"} text={"Samborians"} />. The pinnacle of her career was her appointment to the Abyssinian Fist, which she received through the support of the Emperor himself. Despite the girl's eagerness and skills, few were prepared to believe that Paulinaer really deserved to command the legendary 1st Strike Fleet of Domination. The same one that repeatedly saved the very statehood and people of the Eilireé.
                </p>
                <h3 className="title-bg"><TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Revolution in the Monarchy"} /> (<TextInlineLink name={"book_cydonian_girl"} />)</h3>
                <p className="card-text text-justify">
                    The girl, realizing the chance she had and how difficult it would be to prove to her subordinates that she was worthy of the position, began with a double determination to defeat the pirates in the <TextInlineLink name={"bs_state_free_worlds"} text={"Free worlds"} /> and even entered into a personal conflict with <TextInlineLink name={"bs_char_hector_salinger"} text={"Hector Salinger"} />, leader of the <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Feathered Serpent"} />.
                </p>
                <p className="card-text text-justify">
                    Paulinaer was received very coldly at court, as were her parents. She had only one friend, the first Crown Princess of Elsinora. The girls quickly found a common language, not even the age difference prevented it. They often spent their evenings over a glass of cocoa, discussing their dreams of reforming the country and sharing their red-hot thoughts about how obsolete the Eiliran traditionalist order of things was. Both came to the conclusion that after Beisarion's resignation, the Domination should be transformed into a semblance of the Priority with its liberal meritocracy.
                </p>
                <p className="card-text text-justify">
                    So when civil war broke out in the <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Monarchy"} /> and Elsinora openly admitted that she had no plans to help the Monarch retain power, Paulinaer supported her. She went against the will of her ruler and only imitated the preparation of troops for the invasion of Earthling space. Instead, she raced her warships for weeks of drills and maintenance, effectively rendering the <span className="strong">Abyssinian Fist</span> inoperable. As a result, the Earthlings received no help, although the Emperor was confident that the <span className="strong">Princess</span> would not disobey him and would support the last of the Alcanarra. Because of this, a colossal conflict erupted at court, threatening Paulinaer with the loss not only of her rank but also of her freedom (and possibly her life). Elsinora conveyed to her that the emperor planned to appoint another heir, and that Elsinora and all those close to her would be subjected to reprisals. The girls urgently needed a backup plan.
                </p>
            </>
    }
}
